<template>
    <div class="main">
        <div>
            <img class="logo-img" src="../../assets/image/logo.png"/>
        </div>
        <div>
            <img class="top-img" src="../../assets/image/share-top-img.png"/>
        </div>
        <div class="top-big-text">寻找牛人</div>
        <div class="comp-show-box">
            <div><img class="content-bg-img" src="../../assets/image/y.png"/></div>
            <div class="comp-show-title">01 关于我们</div>
            <div class="comp-show-text">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;香港人康国际生物科技有限公司位于河北省石家庄市，是集科研、生产、销售、服务于一体的集团化企业，经营领域涉及中医养生、理疗服务、健康食品、功能食品等领域。目前在国内拥有6个养生连锁机构，四十余家分公司遍布华北、东北、西北三个地区。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主营业务包含两大类，一为养生理疗类，如头疗、腹疗、筋疗、腿疗、膀胱经排毒、淋巴排毒、泥灸、雷火灸、隔姜灸等，坚持为顾客提供最优质的服务；二为保健食品类，如细胞能量液、南极磷虾油、辅酶Q10、蓝莓叶黄素、安捷康、益生菌等，坚持在全球范围内精选优质产品，严格把控产品品质，将产品质量视为企业生命。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司致力打造中国网络大、品质好、消费者信赖的健康产业连锁集团，像一艘远航的巨轮，满载希冀和梦想，以更加开放的胸怀，开拓进取的精神，谦逊的姿态和所有合作伙伴共创美好明天！<br/>
            </div>
        </div>
        <div class="position-info">
            <div class="title-box">
                <div class="title-bg">POSITION</div>
                <div class="title-text">02 招聘岗位</div>
                <div class="title-line"></div>
            </div>
            <div v-for="item in nowData.jobNeedList" v-bind:key="item.id" class="position-list">
                <div class="position-list-left">{{item.jobName}}</div>
                <div class="position-list-right">薪酬：<span style="color: #FF8F00;">{{item.salaryMin/1000}}-{{item.salaryMax/1000}}K</span></div>
            </div>
        </div>
        <div class="bottom-button-box">
            <div v-if="nowData.taskStatus < 3" @click="toAdd" class="bottom-button">投&nbsp;&nbsp;递</div>
            <div v-else class="bottom-button-no">投&nbsp;&nbsp;递</div>
        </div>
    </div>
</template>

<script>
    import { getJobInfo, getWxJsSdkConfig } from '@/api/service'
    import {Toast} from "vant";
    const wx = window.wx;
    export default {
        name: 'Reward',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                nowUserInfo: {}, // 当前用户信息
                query: '',
                nowData: ''
            }},
        created() {
            const query = this.$route.query
            if(!query.taskId){
                Toast('参数缺失')
            }
            this.query = query
            this.getAllParam()
        },
        mounted:function(){

        },
        methods: {
            // 获取详情
            getAllParam() {
                const postData = {}
                postData.taskId = this.query.taskId
                postData.type = 'pub'
                getJobInfo(postData).then((response) => {
                    this.nowData = response.data
                    this.getConfig()
                })
            },
            // 获取sdk  配置
            getConfig() {
                let that = this
                let postData = {}
                postData.url = window.location.href
                getWxJsSdkConfig(postData).then(response => {
                    that.initConfig(response.data);
                })
            },
            // 初始化 Config
            initConfig(jsSdkConfig) {
                const nowData = this.nowData
                wx.config({
                    debug: false,
                    appId: jsSdkConfig.appId, // 必填，公众号的唯一标识
                    timestamp: jsSdkConfig.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jsSdkConfig.noncestr, // 必填，生成签名的随机串
                    signature: jsSdkConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData',"onMenuShareTimeline","onMenuShareAppMessage","onMenuShareQQ","onMenuShareWeibo","onMenuShareQZone"], //必填，传入需要使用的接口名称
                });
                wx.ready(function () {
                    // 监听分享到微信及分享到朋友圈
                    wx.updateAppMessageShareData({
                        title: nowData.shareTitle, // 分享标题
                        desc: nowData.shareIntro, // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://newplatform-1301970825.cos.ap-beijing.myqcloud.com/images/2022-02-17/1645069481418605.png', // 分享图标
                        success: function () {
                            // 设置成功
                            console.log('配置成功')
                        },
                        fail: function () {
                        }
                    })
                    // 监听分享到朋友圈及 分享到qq空间
                    wx.updateTimelineShareData({
                        title: nowData.shareTitle, // 分享标题
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://newplatform-1301970825.cos.ap-beijing.myqcloud.com/images/2022-02-17/1645069481418605.png', // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    })
                    wx.onMenuShareTimeline({
                        title: nowData.shareTitle, // 分享标题
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://newplatform-1301970825.cos.ap-beijing.myqcloud.com/images/2022-02-17/1645069481418605.png', // 分享图标
                        success: function () {
                            // 用户点击了分享后执行的回调函数
                        },
                        fail: function () {
                        }
                    })
                })
            },
            // 跳转到提交
            toAdd() {
                this.$router.push({ path: '/recruit', query: this.query})
            }
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        height: auto;
        background-color:#1243D5;
        background: url('../../assets/image/share-bg.png')  no-repeat center center fixed ;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .top-img{
        width: 100vw;
        height: 230px;
    }
    .logo-img{
        width: 101px;
        height: 27px;
        margin: 50px 0 30px 50px;
    }
    .top-big-text{
        width: 100vw;
        text-align: center;
        height: 80px;
        font-size: 45px;
        line-height: 80px;
        color: #d7ddf1;
        font-weight: bold;
        text-shadow: #0F3CB8 4px 4px 4px;
        transform:rotate(-10deg)
    }
    .comp-show-box{
        width: calc(100vw - 40px);
        height: 558px;
        background: rgba(255,255,255,0);
        position: relative;
        margin: 20px;
    }
    .content-bg-img{
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100vw - 40px);
        height: 558px;
    }
    .comp-show-title{
        position: absolute;
        top: 95px;
        left: 20px;
        height: 26px;
        font-size: 26px;
        color: #303133;
        line-height: 30px;
        font-weight: bold;
    }
    .comp-show-text{
        position: absolute;
        top: 150px;
        padding: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #606166;
    }
    .position-info{
        background: #FFFFFF;
        border-radius: 12px;
        width: calc(100vw - 80px);
        margin: 20px;
        padding: 20px;
    }
    .title-bg{
        width: 220px;
        height: 40px;
        font-size: 40px;
        color: #F64B4B;
        line-height: 46px;
        letter-spacing: 4px;
        background: linear-gradient(180deg, #EDEDED 0%, #FBFAFD 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .title-text{
        height: 26px;
        font-size: 26px;
        color: #303133;
        line-height: 30px;
        font-weight: bold;
    }
    .title-line{
        width: 134px;
        height: 8px;
        background: #7369FF;
        opacity: 0.4;
    }
    .position-list{
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
    }
    .position-list-left{
        text-align: left;
    }
    .position-list-right{
        text-align: right;
    }
    .bottom-button{
        width: 240px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(310deg, #F19106 0%, #FBCB17 100%);
        border-radius: 22px;
    }
    .bottom-button-no{
        width: 240px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #ffffff;
        background: #999999;
        border-radius: 22px;
    }
    .bottom-button-box{
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 84px;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
    }
</style>
